<template>
  <div class="d-flex align-center">
    <!-- ЛЕВАЯ СТОРОНА -->
    <!-- <div /> -->

    <!-- ПРАВАЯ СТОРОНА -->
    <div
      class="d-flex align-center"
    >
      <!-- ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ -->
      <!-- <v-tooltip
        v-if="profile.manageUsers"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            icon
            class="mr-2"
            v-on="on"
            @click="$store.dispatch('users/openDialog', 'add')"
          >
            <v-icon>
              mdi-account-plus
            </v-icon>
          </v-btn>
        </template>
        <span>Добавить пользователя</span>
      </v-tooltip> -->

      <!-- ОБНОВИТЬ СПИСОК -->
      <!-- <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            class="ml-2 mr-4"
            color="primary"
            icon
            :disabled="loading.mainTable"
            v-on="on"
            @click="$store.dispatch('users/loadCurrentPage')"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip> -->

      <v-select
        v-model="env"
        :items="['prod', 'dev']"
        label="Environment"
        class="mr-3"
        @input="search"
      />

      <v-select
        v-model="source"
        :items="['local-api', 'backend', 'backend-sync-php']"
        label="Источник"
        class="mr-3"
        clearable
        @input="search"
      />

      <v-select
        v-model="level"
        :items="['FATAL', 'ERROR', 'WARN', 'INFO', 'DEBUG', 'TRACE']"
        label="Уровень"
        class="mr-3"
        clearable
        @input="search"
      />

      <!-- ПОИСК ПО USERNAME -->
      <v-text-field
        v-model="username"
        label="Email"
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        class="mr-3"
        @keydown.enter.prevent="search"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>

      <!-- ПОИСК ПО ID БП -->
      <v-text-field
        v-model="browserProfileId"
        label="ID профиля"
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        class="mr-3"
        @keydown.enter.prevent="search"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>

      <!-- ПОИСК ОПЕРАЦИИ -->
      <v-text-field
        v-model="actionType"
        label="Поиск операции"
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        class="mr-3"
        @keydown.enter.prevent="search"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>

      <!-- ПОИСК В СООБЩЕНИИ -->
      <v-text-field
        v-model="message"
        label="Поиск сообщения"
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        @keydown.enter.prevent="search"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'LogsTopbar',

  computed: {
    ...mapGetters({
      // loading: 'users/loading',
      profile: 'main/profile',
    }),
    ...mapFields('logs', ['filters']),
    env: {
      get() {
        return this.$route.query.env || 'prod';
      },
      set(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            env: value,
          },
        });
      },
    },
    level: {
      get() {
        return this.$route.query.level;
      },
      set(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            level: value,
          },
        });
      },
    },
    source: {
      get() {
        return this.$route.query.source;
      },
      set(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            source: value,
          },
        });
      },
    },
    browserProfileId: {
      get() {
        return this.$route.query.browserProfileId;
      },
      set(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            browserProfileId: value,
          },
        });
      },
    },
    username: {
      get() {
        return this.$route.query.username;
      },
      set(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            username: value,
          },
        });
      },
    },
    actionType: {
      get() {
        return this.$route.query.actionType;
      },
      set(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            actionType: value,
          },
        });
      },
    },
    message: {
      get() {
        return this.$route.query.message;
      },
      set(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            message: value,
          },
        });
      },
    },
  },
  methods: {
    search() {
      this.$store.dispatch('logs/loadLogs');
    },
  },
};
</script>
