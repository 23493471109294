<template>
  <div>
    <!-- <dialog-add-log v-if="dialogs.add" />
    <dialog-change-password v-if="dialogs.changePassword" /> -->
    <topbar />
    <main-table />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MainTable from '../components/logs/LogsMainTable.vue';
import Topbar from '../components/logs/LogsTopbar.vue';
// import DialogAddLog from '../components/logs/LogsDialogAddLog.vue';
// import DialogChangePassword from '../components/logs/LogsDialogChangePassword.vue';

export default {
  name: 'Logs',

  components: {
    MainTable,
    Topbar,
    // DialogAddLog,
    // DialogChangePassword,
  },

  computed: {
    ...mapGetters({
      // dialogs: 'logs/dialogs',
    }),
  },

  created() {
    this.$store.dispatch('logs/loadLogs');
  },

  beforeDestroy() {
    // this.$store.dispatch('logs/clearSelected');
  },
};
</script>
