var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.logItemPayloadDialog),callback:function ($$v) {_vm.logItemPayloadDialog=$$v},expression:"logItemPayloadDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 blue"},[_vm._v(" Данные ")]),_c('v-card-text',{staticClass:"pt-3"},[_c('json-viewer',{attrs:{"value":_vm.logItemPayload,"expand-depth":5,"copyable":"","boxed":"","sort":""}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.logItemPayloadDialog = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.logs.all,"item-key":"id","headers":_vm.cols,"fixed-header":"","height":_vm.innerHeight - 16 - 46 - 84,"items-per-page":_vm.footerOptions.itemsPerPage,"hide-default-footer":"","show-select":"","loading":_vm.loading.mainTable},scopedSlots:_vm._u([{key:"item.level",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","outlined":"","color":_vm.getLevelColor(item.level)}},[_vm._v(" "+_vm._s(item.level)+" ")])]}},{key:"item.actionType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.actionType ? item.actionType : '-')+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.username)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).locale('ru').calendar())+" ")]}},{key:"item.browserProfile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.browser_profile_id)+" ")]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message ? item.message : '-')+" ")]}},{key:"item.payload",fn:function(ref){
var item = ref.item;
return [(JSON.stringify(item.payload) !== '[]')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showPayload(item.payload)}}},[_c('v-icon',[_vm._v("mdi mdi-eye")])],1):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-data-footer',{attrs:{"options":_vm.footerOptions,"pagination":_vm.footerPagination,"items-per-page-options":[10, 30, 50],"show-first-last-page":""},on:{"update:options":_vm.updateFooterOptions}})]},proxy:true}],null,true),model:{value:(_vm.logs.selected),callback:function ($$v) {_vm.$set(_vm.logs, "selected", $$v)},expression:"logs.selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }