<template>
  <v-card>
    <!-- ДИАЛОГ С ДАННЫМИ -->
    <v-dialog
      v-model="logItemPayloadDialog"
      width="700"
    >
      <v-card>
        <v-card-title class="text-h5 blue">
          Данные
        </v-card-title>

        <v-card-text class="pt-3">
          <json-viewer
            :value="logItemPayload"
            :expand-depth="5"
            copyable
            boxed
            sort
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="logItemPayloadDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ТАБЛИЦА -->
    <v-data-table
      v-model="logs.selected"
      :items="logs.all"
      item-key="id"
      :headers="cols"
      fixed-header
      :height="innerHeight - 16 - 46 - 84"
      :items-per-page="footerOptions.itemsPerPage"
      hide-default-footer
      show-select
      :loading="loading.mainTable"
    >
      <template #[`item.level`]="{item}">
        <v-chip
          label
          small
          outlined
          :color="getLevelColor(item.level)"
        >
          {{ item.level }}
        </v-chip>
      </template>

      <template #[`item.actionType`]="{item}">
        {{ item.actionType ? item.actionType : '-' }}
      </template>

      <template #[`item.user`]="{item}">
        {{ item.username }}
      </template>

      <template #[`item.createdAt`]="{item}">
        {{ moment(item.created_at).locale('ru').calendar() }}
      </template>

      <template #[`item.browserProfile`]="{item}">
        {{ item.browser_profile_id }}
      </template>

      <template #[`item.message`]="{item}">
        {{ item.message ? item.message : '-' }}
      </template>

      <template #[`item.payload`]="{item}">
        <v-btn
          v-if="JSON.stringify(item.payload) !== '[]'"
          icon
          @click="showPayload(item.payload)"
        >
          <v-icon>mdi mdi-eye</v-icon>
        </v-btn>
      </template>

      <template #footer>
        <v-data-footer
          :options="footerOptions"
          :pagination="footerPagination"
          :items-per-page-options="[10, 30, 50]"
          show-first-last-page
          @update:options="updateFooterOptions"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import moment from 'moment';
import JsonViewer from 'vue-json-viewer';

// import CellActions from './LogsMainTableCellActions.vue';

export default {
  name: 'LogsMainTable',

  components: {
    // CellActions,
    JsonViewer,
  },

  data() {
    return {
      moment,
      logItemPayloadDialog: false,
      logItemPayload: null,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'logs/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'logs/pagination',
    }),
    ...mapFields('logs', ['logs']),

    cols() {
      const cols = [];

      cols.push({
        text: 'Источник',
        value: 'source',
      });

      cols.push({
        text: 'Уровень',
        value: 'level',
      });

      cols.push({
        text: 'Пользователь',
        value: 'user',
      });

      cols.push({
        text: 'Браузерный профиль',
        value: 'browserProfile',
      });

      cols.push({
        text: 'Операция',
        value: 'actionType',
      });

      cols.push({
        text: 'Сообщение',
        value: 'message',
      });

      cols.push({
        text: 'Данные',
        value: 'payload',
      });

      cols.push({
        text: 'Создано',
        value: 'createdAt',
      });

      return cols;
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },

  methods: {
    updateFooterOptions(options) {
      this.$store.dispatch('logs/loadLogs', { page: options.page, itemsPerPage: options.itemsPerPage });
    },

    getLevelColor(level) {
      const colors = {
        ERROR: 'red',
        FATAL: 'red',
        WARN: 'orange',
        DEBUG: 'blue',
        INFO: 'blue',
        TRACE: 'blue',
      };

      return colors[level];
    },

    showPayload(payload) {
      this.logItemPayload = payload;
      this.logItemPayloadDialog = true;
    },
  },
};
</script>
